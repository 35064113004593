<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1>Registrar Usuarios</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>Registro de usuarios</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="24">
        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <h2>Datos de registro</h2>
          <el-form
            :model="form"
            status-icon
            :rules="rules"
            ref="form"
            :label-position="(innerWidth > 1300) ? 'left' : 'top'"
            label-width="150px"
            class="demo-form"
          >
            <el-form-item label="Nombre" prop="name">
              <el-input
                v-model="form.name"
                autocomplete="off"
                placeholder="Ingresa el Nombre"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="Email" prop="email">
              <el-input
                v-model="form.email"
                autocomplete="off"
                placeholder="Ingresa el Email"
                clearable
              ></el-input>
            </el-form-item>
              <el-link
                icon="el-icon-edit"
                @click="changePassword = true"
                v-if="edit"
              >Actualizar Contraseña</el-link>

            <template v-if="!edit || changePassword">
              <el-form-item label="Contraseña" prop="password">
                <el-input
                  type="password"
                  v-model="form.password"
                  autocomplete="off"
                  placeholder="Ingrese la contraseña"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="Confirmar Contraseña" prop="checkPass">
                <el-input
                  type="password"
                  v-model="form.checkPass"
                  autocomplete="off"
                  placeholder="Repita la contraseña"
                  clearable
                ></el-input>
              </el-form-item>
            </template>
            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('form')"
                :loading="loading"
                icon="el-icon-check"
                v-if="!edit"
              >Guardar</el-button>
              <el-button
                type="warning"
                @click="submitForm('form')"
                :loading="loading"
                icon="el-icon-edit"
                v-else
              >Actualizar</el-button>
              <el-button @click="resetForm('form')" icon="el-icon-close">Cancelar</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <!-- grid -->
        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-table
            :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
            style="width: 100%; margin-top:20px;"
            height="400"
          >
            <el-table-column label="Nombre" prop="name"></el-table-column>
            <el-table-column label="Email" prop="email"></el-table-column>
            <el-table-column align="right">
              <template slot="header" slot-scope="scope">
                <el-input v-model="search" size="mini" placeholder="Digite el nombre" />
              </template>
              <template slot-scope="scope" v-if="scope.row.id !== 1">
                <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                  <el-button 
                  size="mini" 
                  type="warning" 
                  @click="handleEdit(scope.$index, scope.row)" 
                  icon="el-icon-edit" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    icon="el-icon-delete" circle
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { store, update, deleteUser, getById, getUsers, credentials } from "@/api/user";
// import { getStores } from "@/api/store";
export default {
  name: "Register",
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Porfavor ingrese la contraseña"));
      } else {
        if (this.form.checkPass !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Porfavor repita la contraseña"));
      } else if (value !== this.form.password) {
        callback(new Error("Las contraseñas no coinciden!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      edit: false,
      changePassword: false,
      innerWidth: 0,
      labelPosition: "top",
      form: {
        id: "",
        name: "",
        password: "",
        checkPass: "",
        email: ""
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        name: [
          {
            required: true,
            message: "Porfavor ingrese el nombre",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Porfavor ingrese el email",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Email invalido",
            trigger: ["blur", "change"]
          }
        ],
      },
      tableData: [],
      stores: [],
      credentials: [],
      search: ""
    };
  },
  methods: {
    resizeOpenNav() {
      this.innerWidth = window.innerWidth;
    },
    submitForm(formName) {
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.edit) {
            this.updateForm();
          } else {
            this.saveForm();
          }
        } else {
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      store(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.getUsers();
            this.resetForm("form");
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function(error) {
          this.loading = false;
          console.log("Err: ", error);
        });
    },
    updateForm() {
      let me = this
      update(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.getUsers();
            this.resetForm("form");
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function(error) {
          console.log("Err: ", error);
          me.loading = false;
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.edit = false;
      this.changePassword = false;
    },
    getUsers() {
      getUsers()
        .then(({ data }) => {
          this.tableData = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // getStores() {
    //   getStores()
    //     .then(({ data }) => {
    //       this.stores = data;
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // },
    getCredentials() {
      credentials()
        .then(({ data }) => {
          this.credentials = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // table
    handleEdit(index, row) {
      getById(row.id)
        .then(({ data }) => {
          this.edit = true;
          this.form = {
            id: data.id,
            name: data.name,
            email: data.email
          };
        })
        .catch(function(error) {
          console.log("Err: ", error);
        });
    },
    handleDelete(index, row) {
      deleteUser(row.id)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.getUsers();
            this.resetForm("form");
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function(error) {
          this.loading = false;
          console.log("Err: ", error);
        });
    }
  },
  mounted() {
    this.getUsers();
    // this.getStores();
    // this.getCredentials();
    this.resizeOpenNav();
    window.addEventListener("resize", this.resizeOpenNav);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeOpenNav);
  }
};
</script>

<style lang="scss">
.el-select {
  width: 100%;
}
.el-icon-circle-check {
  color: #1cc529;
}
</style>
