<template>
  <div class="scrollable only-y">
    <div
      class="card-base card-shadow--medium p-30 content-terms"
      v-loading="loading"
    >
      <div class="terms"  v-html="terms"></div>
    </div>
  </div>
</template>
<script>
import { getPqrTerms } from "@/api/pqr";
export default {
  name: "terms",
  data() {
    return {
      loading: false,
      terms: null,
    };
  },
  methods: {
    getTerms() {
      let me = this;
      me.loading = true;
      getPqrTerms()
        .then(({ data }) => {
          me.terms = data ? data.terms : null;
          me.loading = false;
        })
        .catch(function (error) {
          me.loading = false;
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
  },
  mounted() {
    this.getTerms();
  },
};
</script>
<style lang="scss" scope>
.content-terms{
  text-align: justify;
}
.terms {
  width: 60%;
  margin: 0 auto;
}
</style>