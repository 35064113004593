<template>
  <vue-scroll class="scroller">
    <div class="content-img" style="text-align: center;">
      <img class="image-slide" :src="imageUrl+'/slide.png'" alt="slide" />
    </div>
  </vue-scroll>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: process.env.VUE_APP_ROOT+'images/'+process.env.VUE_APP_ROOT_FOLDER,
      url: process.env.VUE_APP_ROOT_API,
    }
  },
  mounted() {
    // console.log(process.env);
  },
}
</script>

<style lang="scss" scoped>
.selected-item {
  background-color: #c3ffb0;
}

.image-bingo {
  width: 80px;
}

.bingo {
  margin-top: 40px;
  text-align: -webkit-center;
}

.container_bingo {
  // display: flex;
  display: table-cell;
  flex-wrap: wrap;
  // width: 400px;
  margin-top: 0px;
}

.cell {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 1px solid #aaa;
  font-size: 30px;
  // margin-right: -1px;
  // margin-bottom: -1px;
  cursor: pointer;
  // circulo
  // -moz-border-radius: 50%;
  // -webkit-border-radius: 50%;
  // border-radius: 50%;
  margin: 1px;
}

.cell-leter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-right: -1px;
  margin-bottom: -1px;
  font-weight: bold;
  font-size: 50px;
}

// .cell:nth-child(1n) {
//   margin-right: 0;
// }
// .cell:nth-child(27n) {
//   margin-bottom: 0;
// }

@media (max-width: 1200px) {
  .image-slide {
    width: 900px;
  }
}

@media (max-width: 980px) {
  .image-slide {
    width: 600px;
  }
}

@media (max-width: 650px) {
  .image-slide {
    width: 400px;
  }
}

@media (max-width: 430px) {
  .image-slide {
    width: 200px;
  }
}
</style>


