<template>
  <div>
    <h1>Indicadores PQR</h1>
  </div>
</template>
<script>

export default {
  name: "PqrIndicators",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    
  },
  components: {
  }
};
</script>
<style lang="scss" scope>

</style>