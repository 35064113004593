<template>
  <div class="not-found">
    <div class="msg-box">
      <h1 class="animated fadeInDown">401 Inautorizado</h1>
      <h2 class="animated fadeInUp">
        ¡Uy!
        <br />Parece que no estas autorizado a entrar.
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
</style>
