<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-tabs type="border-card" @tab-click="setData">
        <el-tab-pane label="Código CUN">
          <label slot="label"
            ><i class="el-icon-collection"></i> Código CUN</label
          >
          <el-row :gutter="20">
            <el-col :xs="7" :sm="4" :md="2" :lg="2" :xl="2">
              <el-input
                size="small"
                maxlength="4"
                placeholder="OI"
                v-model="oi"
                clearable
              ></el-input>
            </el-col>
            <el-col :xs="7" :sm="4" :md="2" :lg="2" :xl="2">
              <el-input
                size="small"
                maxlength="2"
                placeholder="AA"
                v-model="aa"
                clearable
              ></el-input>
            </el-col>
            <el-col :xs="10" :sm="6" :md="4" :lg="4" :xl="4">
              <el-input
                size="small"
                maxlength="10"
                placeholder="CR"
                v-model="cr"
                clearable
              ></el-input>
            </el-col>
            <el-col :xs="24" :sm="6" :md="4" :lg="4" :xl="4">
              <el-button
                type="primary"
                size="mini"
                class="btn-search"
                round
                :loading="loading"
                @click="searchData('cun')"
                ><i class="el-icon-search"></i> Consultar</el-button
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-alert
                title="Debe diligenciar los campos del formulario para la conuslta"
                type="warning"
                :closable="false"
                show-icon
                v-if="msnShow"
              >
              </el-alert>
            </el-col>
            <el-col :span="24" v-if="dataSearch">
              <el-row class="data-row">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div><strong>Nombre</strong></div>
                  <div>{{ dataSearch ? getName(dataSearch) : "" }}</div>
                  <div><strong>Documento</strong></div>
                  <div>{{ dataSearch ? dataSearch.identificacion : "" }}</div>
                  <div><strong>Operador / Proveedor</strong></div>
                  <div>{{ dataSearch ? dataSearch.client.name : "" }}</div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-alert type="info" show-icon>
                    <label slot="title"
                      >Fecha de consulta: {{ date_search }}</label
                    >
                    <div>
                      A continuación se presenta el resultado de su consulta.
                      Este resultado fue obtenido del operador / proveedor:
                      <strong>{{
                        dataSearch ? dataSearch.client.name : ""
                      }}</strong>
                    </div>
                  </el-alert>
                </el-col>
                <el-col :span="24" class="table-data">
                  <el-table
                    :data="tableData"
                    stripe
                    style="width: 100%"
                    height="250"
                  >
                    <el-table-column prop="cun_sic" label="CUN" width="180">
                    </el-table-column>
                    <el-table-column
                      prop="fecha_cun"
                      label="Fecha asignación CUN"
                      width="180"
                    >
                      <template slot-scope="scope">
                        <div>{{ scope.row.created_at.substring(0, 10) }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="created_at"
                      label="Fecha estimada de respuesta"
                      width="220"
                    >
                      <template slot-scope="scope">
                        <div>{{ scope.row.fecha_vence.substring(0, 10) }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="state.estado"
                      label="Estado del tramite"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="procedure.tipo_tramite"
                      label="Tipo del tramite"
                    >
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Docuemnto">
          <label slot="label"><i class="el-icon-postcard"></i> Docuemnto</label>
          <el-row :gutter="20">
            <el-col :xs="10" :sm="6" :md="4" :lg="4" :xl="4">
              <el-input
                size="small"
                maxlength="15"
                placeholder="Documento"
                v-model="doc"
                clearable
              ></el-input>
            </el-col>
            <el-col :xs="24" :sm="6" :md="4" :lg="4" :xl="4">
              <el-button
                type="primary"
                size="mini"
                class="btn-search"
                round
                :loading="loading"
                @click="searchData('doc')"
                ><i class="el-icon-search"></i> Consultar</el-button
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-alert
                title="Debe diligenciar los campos del formulario para la conuslta"
                type="warning"
                :closable="false"
                show-icon
                v-if="msnShow"
              >
              </el-alert>
            </el-col>
            <el-col :span="24" v-if="dataSearchDoc">
              <el-row class="data-row">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div><strong>Nombre</strong></div>
                  <div>{{ dataSearchDoc ? getName(dataSearchDoc) : "" }}</div>
                  <div><strong>Documento</strong></div>
                  <div>
                    {{ dataSearchDoc ? dataSearchDoc.identificacion : "" }}
                  </div>
                  <div><strong>Operador / Proveedor</strong></div>
                  <div>
                    {{ dataSearchDoc ? dataSearchDoc.client.name : "" }}
                  </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-alert type="info" show-icon>
                    <label slot="title"
                      >Fecha de consulta: {{ date_search }}</label
                    >
                    <div>
                      A continuación se presenta el resultado de su consulta.
                      Este resultado fue obtenido del operador / proveedor:
                      <strong>{{
                        dataSearchDoc ? dataSearchDoc.client.name : ""
                      }}</strong>
                    </div>
                  </el-alert>
                </el-col>
                <el-col :span="24" class="table-data">
                  <el-table
                    :data="tableDataDoc"
                    stripe
                    style="width: 100%"
                    height="250"
                  >
                    <el-table-column prop="cun_sic" label="CUN" width="180">
                    </el-table-column>
                    <el-table-column
                      prop="fecha_cun"
                      label="Fecha asignación CUN"
                      width="180"
                    >
                      <template slot-scope="scope">
                        <div>{{ scope.row.created_at.substring(0, 10) }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="created_at"
                      label="Fecha estimada de respuesta"
                      width="220"
                    >
                      <template slot-scope="scope">
                        <div>{{ scope.row.fecha_vence.substring(0, 10) }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="state.estado"
                      label="Estado del tramite"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="procedure.tipo_tramite"
                      label="Tipo del tramite"
                    >
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { getPqrByCun, getPqrByDocument } from "@/api/pqr";
export default {
  name: "searches",
  data() {
    return {
      loading: false,
      msnShow: false,
      oi: "",
      aa: "",
      cr: "",
      date_search: moment().locale("es").format("MMMM Do YYYY, h:mm:ss a"),
      operator: "",
      dataSearch: null,
      tableData: [],
      //
      doc: "",
      dataSearchDoc: null,
      tableDataDoc: [],
    };
  },
  methods: {
    setData(){
      this.oi = '';
      this.aa = '';
      this.cr = '';
      this.doc = '';
    },
    searchData(op) {
      let me = this;
      if (me.oi != "" && me.aa != "" && me.cr != "") {
        if (op == "cun") {
          me.getDataCun();
        }
      } else {
        if (me.doc && op == "doc") {
          me.getDataDocument();
        } else {
          me.loading = false;
          me.msnShow = true;
        }
      }
    },
    getDataCun() {
      let me = this;
      me.loading = true;
      var cun = me.oi + "-" + me.aa + "-" + me.cr;
      me.tableData = [];
      getPqrByCun(cun)
        .then(({ data }) => {
          me.dataSearch = data;
          me.tableData.push(data);
          me.loading = false;
          me.msnShow = false;
        })
        .catch(function (error) {
          me.msnShow = false;
          me.loading = false;
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    getDataDocument() {
      let me = this;
      me.loading = true;
      me.tableDataDoc = [];
      getPqrByDocument(me.doc)
        .then(({ data }) => {
          me.dataSearchDoc = data;
          me.tableDataDoc.push(data);
          me.loading = false;
          me.msnShow = false;
        })
        .catch(function (error) {
          me.msnShow = false;
          me.loading = false;
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    getName(data) {
      return (
        data.primer_nombre.trim() +
        " " +
        (data.segundo_nombre ? data.segundo_nombre.trim() : "") +
        " " +
        ((data.primer_apellido) ? data.primer_apellido.trim() : '') +
        " " +
        ((data.segundo_apellido) ? data.segundo_apellido.trim() : '')
      );
    },
  },
  components: {},
};
</script>
<style lang="scss" scope>
.data-row,
.table-data {
  margin-top: 20px;
}
@media (max-width: 763px) {
  .btn-search {
    margin-top: 15px;
  }
}
</style>