<template>
  <div class="scrollable only-y">
    <v-stepper
      ref="stepper"
      :steps="steps"
      v-model="step"
      @input="validateStep"
    ></v-stepper>
    <!-- Stepper Controls -->
    <template v-if="step === 1">
      <div class="steps-content">
        <el-form
          :model="fieldValidateForm"
          ref="fieldValidateForm"
          label-width="120px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item
                label="Peso en Kilos"
                prop="peso"
                :rules="[{ required: true, message: 'Campo Obligatorio' }]"
              >
                <el-select
                  v-model="fieldValidateForm.peso"
                  placeholder="Seleccione el peso"
                  wi
                >
                  <el-option label="1 Kl" value="1"></el-option>
                  <el-option label="2 Kl" value="2"></el-option>
                  <el-option label="3 Kl" value="3"></el-option>
                  <el-option label="4 Kl" value="4"></el-option>
                  <el-option label="5 Kl" value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <template v-if="step === 2">
      <div class="steps-content">
        <el-form
          :model="form"
          status-icon
          :rules="rules"
          size="small "
          ref="form"
          label-width="150px"
          class="demo-form"
        >
          <el-row :gutter="24">
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Tipo Identificacón" prop="tipoid">
                <el-select v-model="form.tipoid" placeholder="Seleccione">
                  <el-option
                    label="Cédula de Ciudadanía"
                    value="CC"
                  ></el-option>
                  <el-option
                    label="Cédula de Extrangería"
                    value="CE"
                  ></el-option>
                  <el-option label="Nit" value="NI"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="N° Identificación" prop="identificacion">
                <el-input
                  type="text"
                  v-model="form.identificacion"
                  autocomplete="off"
                  maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Primer Nombre" prop="primer_nombre">
                <el-input
                  type="text"
                  v-model="form.primer_nombre"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Segundo Nombre">
                <el-input
                  type="text"
                  v-model="form.segundo_nombre"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Primer Apellido" prop="primer_apellido">
                <el-input
                  type="text"
                  v-model="form.primer_apellido"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Segundo Apellido">
                <el-input
                  type="text"
                  v-model="form.segundo_apellido"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Dirección" prop="direccion">
                <el-input
                  type="text"
                  v-model="form.direccion"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="email">
                <el-input
                  type="email"
                  v-model="form.email"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Telefono Fijo">
                <el-input
                  type="tel"
                  v-model="form.telefono"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Telefono Celular">
                <el-input
                  type="tel"
                  v-model="form.celular"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Fax">
                <el-input
                  type="tel"
                  v-model="form.fax"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Ciudad" prop="pqr_ciudades_id">
                <el-autocomplete
                  class="inline-input"
                  v-model="searchCity"
                  :fetch-suggestions="querySearch"
                  :trigger-on-focus="false"
                  placeholder="Buscar Ciudad"
                  size="medium"
                  autocomplete="nueva-ciudad"
                  @select="handleSelect"
                  ref="city"
                >
                  <template slot-scope="{ item }">
                    <div style="margin-top: 10px">
                      <label class="value_item">
                        <i class="mdi mdi-map-marker-outline"></i>
                        {{ item.ciudad }}
                      </label>
                      <br />
                      <small>{{ item.depto }}</small>
                    </div>
                  </template>
                </el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Tramite" prop="pqr_tipo_tramite_id">
                <el-select
                  v-model="form.pqr_tipo_tramite_id"
                  placeholder="Seleccione"
                  ref="tramite"
                  value-key="id"
                >
                  <el-option
                    v-for="item in tramites"
                    :key="item.id"
                    :label="item.tipo_tramite"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Tipo de PQR" prop="pqr_tipo_queja_id">
                <el-select
                  v-model="form.pqr_tipo_queja_id"
                  placeholder="Seleccione"
                  value-key="id"
                >
                  <el-option
                    v-for="item in quejas"
                    :key="item.id"
                    :label="item.tipo_queja"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                label="Tipo de Servicio"
                prop="pqr_tipo_servicio_id"
              >
                <el-select
                  v-model="form.pqr_tipo_servicio_id"
                  placeholder="Seleccione"
                  value-key="id"
                >
                  <el-option
                    v-for="item in servicios"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Número de Guia" prop="guia">
                <el-input
                  type="text"
                  v-model="form.guia"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="Adjuntar Archivo" prop="archivo">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  accept="image/*,.pdf,.doc,.docx"
                  :limit="1"
                  :action="baseURL"
                  :on-exceed="handleExceed"
                  :file-list="fileList"
                  :auto-upload="false"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                >
                  <el-button size="small" type="primary"
                    >Clic para subir archivo</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    Tamaño menor de 5MB
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="Descripción de la queja" prop="descripcion">
                <el-input
                  type="textarea"
                  v-model="form.descripcion"
                  autocomplete="off"
                  placeholder="Ingresa tu queja"
                  :rows="8"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item>
                <el-checkbox v-model="form.notificaciones"
                  >Chequee si desea recibir notificaciones a su
                  email?</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>
    <el-row :gutter="24">
      <el-col>
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          @click="previousStep"
          >Anterior</el-button
        >
        <el-button
          type="primary"
          @click="submitForm('fieldValidateForm')"
          v-if="step != 2"
        >
          Siguiente
          <i class="el-icon-arrow-right"></i>
        </el-button>
        <el-button
          type="success"
          icon="el-icon-s-promotion"
          @click="submitForm('form')"
          v-else
          >Enviar PQR</el-button
        >
        <!-- <el-button type="info" icon="el-icon-close" @click="$refs.stepper.reset();">Cancelar</el-button> -->
      </el-col>
    </el-row>

    <message
      :dialog="dialogVisible"
      :data="dataPqr"
      @close="dialogVisible = false"
    ></message>
  </div>
</template>
<script>
import message from "../../../views/apps/modules/MessagePqr.vue";
import { VStepper } from "vue-stepper-component";
import { getUser, getToken } from "@/utils/auth";
import { store, getTypesApi, getCities, fileUpload } from "@/api/pqr";

export default {
  name: "pqr",
  components: {
    VStepper,
    message,
  },
  mounted() {
    this.getTypes();
  },
  data() {
    return {
      dialogVisible: false,
      options: [],
      city: {},
      list: [],
      searchCity: "",
      user: getUser(),
      fieldValidateForm: {
        peso: "",
      },
      steps: 2,
      step: undefined,
      previous: false,
      active: 0,
      fileList: [],
      tramites: [],
      quejas: [],
      servicios: [],
      dataPqr: [],
      baseURL: process.env.VUE_APP_ROOT_API + "/pqr/fileUpload",
      form: {
        notificaciones: false,
        pqr_ciudades_id: "",
        pqr_tipo_tramite_id: "",
        pqr_tipo_queja_id: "",
        pqr_tipo_servicio_id: "",
        pqr_estados_id: 6,
        tipoid: "",
        identificacion: "",
        primer_nombre: "",
        segundo_nombre: "",
        primer_apellido: "",
        segundo_apellido: "",
        direccion: "",
        telefono: "",
        celular: "",
        fax: "",
        email: "",
        guia: "",
        descripcion: "",
        peso: "",
      },
      rules: {
        tipoid: [
          { required: true, message: "Campo Obligatorio", trigger: "change" },
        ],
        identificacion: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        primer_nombre: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        primer_apellido: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        direccion: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
          {
            type: "email",
            message: "El email no es valido",
            trigger: ["blur", "change"],
          },
        ],
        pqr_ciudades_id: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        pqr_tipo_tramite_id: [
          { required: true, message: "Campo Obligatorio", trigger: "change" },
        ],
        pqr_tipo_queja_id: [
          { required: true, message: "Campo Obligatorio", trigger: "change" },
        ],
        pqr_tipo_servicio_id: [
          { required: true, message: "Campo Obligatorio", trigger: "change" },
        ],
        guia: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        descripcion: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    submitForm(formName) {
      let me = this;
      this.baseURL = process.env.VUE_APP_ROOT_API + "/pqr/fileUpload";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.step == 1) {
            this.previous = false;
            this.$refs.stepper.next();
            this.form.peso = this.fieldValidateForm.peso;
          } else {
            if (formName !== "fieldValidateForm") {
              store(this.form)
                .then(({ data }) => {
                  console.log("data: ", data);
                  if (data.code === 200) {
                    this.dataPqr = data.data;
                    this.dialogVisible = true;
                    this.baseURL = this.baseURL + "/" + data.data.id;
                    setTimeout(() => {
                      me.submitUpload();
                    }, 300);
                    this.$message({
                      type: "success",
                      message: "PQR enviado exitosamente!",
                    });
                    setTimeout(() => {
                      me.resetForm("form");
                      me.previousStep();
                      setTimeout(() => {
                        me.resetForm("fieldValidateForm");
                      }, 1000);
                    }, 2000);
                  } else {
                    this.$message.error("Error al enviar PQR. " + data.error);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  this.$message.error("Error al enviar PQR.");
                });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getTypes() {
      getTypesApi()
        .then(({ data }) => {
          this.tramites = data.tramites;
          this.quejas = data.quejas;
          this.servicios = data.servicios;
        })
        .catch(function (error) {
          this.$message.error('Error registrar.')
          console.log(error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.searchCity = "";
      this.form.notificaciones = false;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `El límite es 1 archivo, haz cargado ${files.length} archivo esta vez`
      );
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    previousStep() {
      this.previous = true;
      this.$refs.stepper.previous();
    },
    validateStep(prop) {
      if (this.step == 2) {
        this.submitForm("fieldValidateForm");
        if (this.fieldValidateForm.peso == "") {
          this.step = 1;
        }
      }
    },
    querySearch(queryString, cb) {
      if (queryString.length > 3) {
        var me = this;
        getCities(queryString)
          .then(({ data }) => {
            me.options = data.ciudades;
            cb(me.options);
          })
          .catch(function (error) {
            console.log(error);
            // toastr.warning("Error: -" + error);
          });
      }
    },
    handleSelect(item) {
      let me = this;
      this.form.pqr_ciudades_id = item.id;
      this.searchCity = item.ciudad;
      this.$nextTick(() => this.$refs.city.focus());
      setTimeout(() => {
        me.$nextTick(() => this.$refs.tramite.focus());
      }, 400);
    },
  },
};
</script>

<style lang="scss" scope>
.el-autocomplete-suggestion li {
  line-height: 15px;
}
.value_item {
  margin: 0;
  height: 15px;
  font-weight: bold;
}
.el-autocomplete {
  width: 100% !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.steps-content {
  padding: 20px 0 20px 0;
}
</style>