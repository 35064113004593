import Vue from 'vue'
import Router from 'vue-router'

import {
  getUser
} from '@/utils/auth'

//apps
import Dashboard from '../views/apps/Dashboard.vue'
import NewPqr from '../views/apps/modules/NewPqr.vue'
import PqrIndicators from '../views/apps/modules/PqrIndicators.vue'
import Searches from '../views/apps/modules/Searches.vue'
import Terms from '../views/apps/modules/Terms.vue'
import Login from "@/views/pages/authentication/Login.vue";

//pages
import adminpqr from './modules/adminpqr'

//errors
import errors from './modules/errors'

import layouts from '../layout'
import store from '../store'

Vue.use(Router)


const router = new Router({
  mode: 'history',
  base: '/admin',
  routes: [{
      path: '/',
      alias: '/dashboards',
      name: 'Inicio',
      component: Dashboard,
      meta: {
        auth: false,
        nav: true,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'home-outline'
      }
    },
    {
      path: '/pqr',
      alias: '/pqr',
      name: 'Nuevo PQR',
      component: NewPqr,
      meta: {
        auth: false,
        nav: true,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'bell-ring-outline'
      }
    },
    {
      path: '/searches',
      alias: '/searches',
      name: 'Consultas',
      component: Searches,
      meta: {
        auth: false,
        nav: true,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'cloud-search-outline'
      }
    },
    {
      path: '/indicators',
      alias: '/indicators',
      name: 'Indicadores PQR',
      component: PqrIndicators,
      meta: {
        auth: false,
        nav: false,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'chart-bar-stacked'
      }
    },
    {
      path: '/terms',
      alias: '/terms',
      name: 'Terminos y Condiciones',
      component: Terms,
      meta: {
        auth: false,
        nav: true,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'file-document-edit-outline'
      }
    },
    adminpqr,
    {
      path: '/login',
      alias: '/login',
      name: 'Login',
      component: Login,
      meta: {
        auth: false,
        nav: true,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'account-arrow-left-outline'
      }
    },
    {
      path: '/logout',
      beforeEnter(to, from, next) {
        auth.logout()
        next({
          path: '/dashboards'
        })
      }
    },
    errors,
  ]
})


const l = {
  contenOnly() {
    store.commit('setLayout', layouts.contenOnly)
  },
  navLeft() {
    store.commit('setLayout', layouts.navLeft)
  },
  navRight() {
    store.commit('setLayout', layouts.navRight)
  },
  navTop() {
    store.commit('setLayout', layouts.navTop)
  },
  navBottom() {
    store.commit('setLayout', layouts.navBottom)
  },
  set(layout) {
    store.commit('setLayout', layout)
  }
}

//insert here login logic
const auth = {
  loggedIn() {
    // return true
    return store.getters.isLogged
  },
  logout() {
    store.commit('setLogout')
  }
}

router.beforeEach((to, from, next) => {
  let authrequired = false
  if (to && to.meta && to.meta.auth)
    authrequired = true

  //console.log('authrequired', authrequired, to.name)

  if (authrequired) {
    if (auth.loggedIn()) {
      if (to.name === 'Inicio') {
        window.location.href = '/admin/dashboards'
        return false
      } else {
        next()
      }
    } else {
      if (to.name !== 'login') {
        window.location.href = '/admin/dashboards'
        return false
      }
      next()
    }
  } else {
    if (auth.loggedIn() && to.name === 'login') {
      window.location.href = '/admin/login'
      return false
    } else {
      next()
    }
  }

  if (to && to.meta && to.meta.layout) {
    l.set(to.meta.layout)
  }
})

router.afterEach((to, from) => {
  setTimeout(() => {
    store.commit('setSplashScreen', false)
  }, 500)
})

export default router
