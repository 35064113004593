import { render, staticRenderFns } from "./ModalAnswer.vue?vue&type=template&id=14a71d71&scoped=true&"
import script from "./ModalAnswer.vue?vue&type=script&lang=js&"
export * from "./ModalAnswer.vue?vue&type=script&lang=js&"
import style0 from "./ModalAnswer.vue?vue&type=style&index=0&id=14a71d71&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a71d71",
  null
  
)

export default component.exports