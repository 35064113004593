<template>
  <el-dialog
    title="Contestar PQR"
    :visible.sync="open"
    width="40%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
  <label slot="title"><i class="mdi mdi-file-document-edit-outline"></i> Contestar PQR <br> <small>CUN: {{ cun_sic }}</small></label>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-select
          v-model="value"
          filterable
          placeholder="Seleccione un estado"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.estado"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-input
          type="textarea"
          :rows="8"
          placeholder="Ingrese su respuesta"
          v-model="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small"
        ><i class="el-icon-close"></i> Cancelar</el-button
      >
      <el-button type="primary" size="small" @click="answer()" :loading="loading"
        ><i class="el-icon-check"></i> Contestar</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { getState, saveAnswerPqr } from "@/api/administrator";
import { getUser } from "@/utils/auth";
export default {
  props: ["open", "pqr_id", "cun_sic"],
  name: "ModalAnwer",
  data() {
    return {
      loading: false,
      textarea: "",
      options: [],
      value: "",
      user: getUser(),
    };
  },
  methods: {
    answer() {
      this.loading = true
      saveAnswerPqr({
        pqr_id: this.pqr_id,
        user_id: this.user.id,
        state_id: this.value,
        description: this.textarea
      })
        .then(({ data }) => {
          this.loading = false
          this.$message({
            type: "success",
            message: "Registro guardado correctamente",
          });
          this.textarea = '';
          this.value= '';
          this.handleClose()
          console.log(data);
        })
        .catch(function (error) {
          this.loading = false
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    getStatus() {
      getState()
        .then(({ data }) => {
          this.options = data;
        })
        .catch(function (error) {
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    handleClose(done) {
      this.$emit("close", true);
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>