<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" @close="close" :modal-append-to-body="true" :close-on-click-modal="false" width="50%">
      <template slot="title">
        <!-- <i class="el-icon-warning-outline" style="font-weight: bold;font-size: 18px;"></i>  -->
        <span style="vertical-align: middle;font-size: 18px;font-weight: bold;">Información PQR</span>
      </template>
      <span>Señor(a):</span>
      <h3>{{ name }}</h3>
      <div>Se ha generado en nuestro sistea un PQR exitosamente, a continuación encontra el detalle del PQR que se ha guardado.</div>
      <div class="content"><span class="cun">Número de CUN: </span>{{ cun }}</div>
      <div class="content"><span class="queja">Queja: </span>{{ queja }}</div>
      
      <div class="info1">Nuestra empresa tiene un plazo máximo de 15 dias para responder el PQR, los cuales se cumplirán en la fecha <span class="vence">{{ vence }}</span>
        , la respuesta generada se le envirá por email, si habilitó la casilla para la confirmación.
      </div>
      <div class="info2">Recuerde que puede consultar el estado de su PQR en nuestro sitio web (<a href="/admin/searches">Click aqui</a>), 
      también recibirá en su email el número de CUN para la consulta de su PQR.</div>
      
      <div class="info3">Esperamos atenderle de la mejor manera</div>
      <div class="info4">Cordialmente,</div>
      <div class="info5">Departamento de Servicio al Cliente</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" icon="el-icon-close">Cerrar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'messagePqr',
  props: ['dialog', 'data'],
  watch: {
		dialog(newVal, oldVal){
			if(newVal){
        this.dialogVisible = newVal
      }
    },
    data(val){
      this.name = val.nombre_completo,
      this.cun = val.cun_sic,
      this.queja = val.descripcion,
      this.vence = val.fecha_vence
    },
	},
  data() {
    return {
      dialogVisible: false,
      name: '',
      cun: '',
      queja: '',
      vence: '',
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
}
</script>

<style lang="css" scope>
  .cun, .queja, .vence, .info5{
    font-weight: bold;
  }
  .content, .info1, .info2, .info5{
    margin-top: 20px;
  } 
  .info3{
    margin-top: 50px;
  }
  .info4{
    margin-top: 50px;
  }
  
</style>
<style lang="scss">
  .el-dialog{
    z-index: 9999;
  }
</style>