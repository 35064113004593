<template>
  <vue-scroll class="login-page align-vertical">
    <div class="form-wrapper align-vertical-middle">
      <div class="form-box card-base card-shadow--extraLarge">
        <form class="form-box" @submit.prevent="login" v-if="user == null">
          <h3><i class="mdi mdi-account-key"></i> Ingrese a su cuenta</h3>

          <float-label class="styled">
            <input
              type="username"
              name="username"
              placeholder="Nombre de Usuario"
              v-model="username"
            />
          </float-label>
          <float-label class="styled">
            <input
              type="password"
              name="password"
              placeholder="Contraseña"
              v-model="password"
            />
          </float-label>

          <el-alert
            v-show="error"
            title="Error al iniciar sesión"
            type="error"
            show-icon
            :description="errorMsg"
            @close="closeMessageError"
          ></el-alert>
          <div class="flex text-center center pt-30 pb-10">
            <el-button
              plain
              size="small"
              native-type="submit"
              class="login-btn tada animated"
              @click="login"
              :loading="loading"
              >Entrar</el-button
            >
          </div>
        </form>
        <a @click="logout()" v-else>Cerrar Sesion</a>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import { login } from "@/api/login";
import { getUser, setToken, setUser } from "@/utils/auth";

export default {
  name: "Login",
  data() {
    return {
      username:  (process.env.VUE_APP_ROOT === 'http://localhost:8000/') ? 'admin@admin.com' : "",
      password: (process.env.VUE_APP_ROOT === 'http://localhost:8000/') ?  "admin123" : "",
      loading: false,
      error: false,
      errorMsg: "",
      user: getUser(),
    };
  },
  methods: {
    login() {
      this.loading = true;
      if (this.username != "" && this.password != "") {
        login(this.username, this.password, false)
          .then(({ data }) => {
            if (data.code === 200) {
              setToken(data.access_token);
              setUser(data.user);
              let user = JSON.parse(data.user);
              this.$store.commit("setLogin");
              this.$router.push({ path: "/" });
              setTimeout(() => {
                location.reload();
              }, 500);
              // for (var i = 0; i < user.roles.length; i++) {
              //   if (user.roles[i].redirect_to) {
              //     this.$router.push({ path: "/" + user.roles[i].redirect_to });
              //   } else {
              //     this.$router.push({ path: "/404" });
              //   }
              // }
            } else {
              this.error = true;
              this.errorMsg = "Credenciales invalidas";
            }
            this.loading = false;
          })
          .catch((response) => {
            this.error = true;
            this.loading = false;
            // this.errorMsg = error.errors
            console.log(response, "asdf");
          });
      } else {
        this.error = true;
        this.errorMsg = "Compelte los campos por favor";
        this.loading = false;
      }
    },
    logout() {
      this.$router.push({ path: "/logout" });
      location.reload();
    },
    closeMessageError() {
      this.error = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.login-page {
  // background: $text-color;
  margin-left: -30px;
  margin-right: -30px;

  .form-wrapper {
    width: 100%;
  }

  .form-box {
    width: 100%;
    max-width: 340px;
    padding: 30px;
    box-sizing: border-box;
    margin: 20px auto;

    a {
      font-size: 14px;
      color: $text-color-accent;
      text-decoration: none;
      font-weight: 500;
    }

    .image-logo {
      width: 80px;
      margin: 0px auto;
      margin-bottom: 30px;
      display: block;
    }

    .login-btn,
    .social-btn {
      width: 160px;

      &.google {
        margin-bottom: 10px;
        background-color: #d73d32;
        color: white;

        &:hover {
          border-color: #d73d32;
        }
      }
      &.facebook {
        background-color: #3f5c9a;
        color: white;

        &:hover {
          border-color: #3f5c9a;
        }
      }
    }

    .signin-box {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .layout-container .container .view.login-page {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }
}
</style>
