import request from '@/utils/request'

export function store(data) {
  return request({
    url: 'pqr/store',
    method: 'post',
    data
  });
}

export function getTypesApi() {
  return request({
    url: 'pqr/getTypes',
    method: 'get'
  });
}


export function getCities(data) {
  return request({
    url: 'pqr/getSelectCity/' + data,
    method: 'get'
  });
}

export function fileUpload(data) {
  return request({
    url: 'pqr/fileUpload',
    method: 'post',
    data
  });
}

export function getPqrByCun(cun) {
  return request({
    url: 'pqr/getPqrByCun/' + cun,
    method: 'get'
  });
}

export function getPqrByDocument(document) {
  return request({
    url: 'pqr/getPqrByDocument/' + document,
    method: 'get'
  });
}

export function getPqrTerms() {
  return request({
    url: 'pqr/getPqrTerms',
    method: 'get'
  });
}