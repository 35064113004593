<template>
  <vue-scroll class="page-payment-provider">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/pqr-list' }">
          Listado de PQR
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30 view-pqr">
      <el-row :gutter="20">
        <el-col :span="10" class="info" v-loading="loadingData">
          <el-row :gutter="gutter">
            <el-col :span="spanLeft"
              ><span class="title">CUN:</span> {{ dataInfo.cun_sic }}</el-col
            >
            <el-col :span="spanRigth"
              ><span class="title">Nombre:</span>
              {{ concatName(dataInfo) }}</el-col
            >
          </el-row>
          <el-row :gutter="gutter">
            <el-col :span="spanLeft">
              <span class="title">Fecha:</span>
              {{ dataInfo.created_at.substring(0, 10) }} &nbsp;&nbsp;&nbsp;
              <span class="title">Vence:</span>
              {{ dataInfo.fecha_vence.substring(0, 10) }}
            </el-col>
            <el-col :span="spanRigth"
              ><span class="title">Teléfono:</span>
              {{ dataInfo.telefono }}</el-col
            >
          </el-row>
          <el-row :gutter="gutter">
            <el-col :span="spanLeft"
              ><span class="title">Tipo PQR:</span>
              {{ dataInfo.complaint.tipo_queja }}</el-col
            >
            <el-col :span="spanRigth"
              ><span class="title">Trámite:</span>
              {{ dataInfo.procedure.tipo_tramite }}</el-col
            >
          </el-row>
          <el-row :gutter="gutter">
            <el-col :span="spanLeft"
              ><span class="title">Estado:</span>
              {{ dataInfo.state.estado }}</el-col
            >
            <el-col :span="spanRigth"
              ><span class="title">Dirección:</span>
              {{ dataInfo.direccion }}</el-col
            >
          </el-row>
          <el-row :gutter="gutter">
            <el-col :span="spanLeft"
              ><span class="title">Adjuntos:</span>
              <a v-if="dataInfo.adjunto" :href="pathUrlDocs + dataInfo.adjunto" target="_blank">
                {{ dataInfo.adjunto }}</a
              >
              <span v-else> Sin Adjunto</span>
              </el-col
            >
            <el-col :span="spanRigth"
              ><span class="title">Ciudad:</span>
              {{ dataInfo.city.ciudad }}</el-col
            >
          </el-row>
          <el-row :gutter="gutter">
            <el-col :span="24"
              ><span class="title">Email:</span> {{ dataInfo.email }}</el-col
            >
          </el-row>
          <el-row :gutter="gutter">
            <el-col :span="24"
              ><span class="title">Queja:</span>
              {{ dataInfo.descripcion }}</el-col
            >
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24"></el-col>
          </el-row>
        </el-col>
        <el-col :span="14">
          <el-card class="box-card">
            <h2>
              <i class="mdi mdi-history" style="vertical-align: middle"></i>
              Historial de respuestas
              <el-tooltip
                class="item"
                effect="dark"
                content="Imprimir"
                placement="top"
              >
                <el-button size="small" round class="btn-print" :loading="loadingDownload" @click="downloadPqr"
                  ><i class="el-icon-printer"></i> Imprimir</el-button
                >
              </el-tooltip>
            </h2>
            <el-table
              :data="tableData"
              stripe
              style="width: 100%"
              height="300"
              :default-sort="{ prop: 'created_at', order: 'descending' }"
              v-loading="loadingTable"
            >
              <el-table-column
                prop="created_at"
                label="Creado Por"
                width="150"
                sortable
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.user.name }}</div>
                  <small>{{ scope.row.created_at.substring(0, 10) }}</small>
                </template>
              </el-table-column>
              <el-table-column prop="descripcion_detalle" label="Descripción">
              </el-table-column>
              <el-table-column prop="estado" label="Cod. Estado" width="100">
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </vue-scroll>
</template>
<script>
import { getPqrById, downloadPqr } from "@/api/administrator";
export default {
  name: "ViewPqr",
  data() {
    return {
      pathUrlDocs: process.env.VUE_APP_ROOT + "static/images/uploads/",
      loadingData: false,
      loadingTable: false,
      loadingDownload: false,
      spanLeft: 14,
      spanRigth: 10,
      gutter: 20,
      idPqr: null,
      tableData: [],
      dataInfo: null,
    };
  },
  methods: {
    downloadPqr(row) {
      this.loadingDownload = true;
      downloadPqr({id: this.idPqr})
        .then(({ data }) => {
          // console.log(data);
          this.downloadFile(data, "Soporte PQR");
        })
        .catch(function (error) {
          console.log(error);
          this.loadingDownload = false;
          this.$message.error("Error.");
        });
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
      this.loadingDownload = false;
    },
    getPqrById() {
      this.loadingData = true;
      this.loadingTable = true;
      getPqrById(this.idPqr)
        .then(({ data }) => {
          // console.log('datos',data);
          this.dataInfo = data;
          this.tableData = data.detail;
          this.loadingData = false;
          this.loadingTable = false;
        })
        .catch(function (error) {
          this.loadingTable = false;
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    concatName(data) {
      return (
        data.primer_nombre +
        " " +
        this.extractNull(data.segundo_nombre) +
        " " +
        this.extractNull(data.primer_apellido) +
        " " +
        this.extractNull(data.segundo_apellido)
      ).trim();
    },
    extractNull(data) {
      return data == null || data == "null" ? "" : data;
    },
  },
  mounted() {
    this.idPqr = this.$route.params.id;

    this.getPqrById();
  },
};
</script>
<style lang="scss">
.btn-print {
  float: right;
}
.info {
  font-size: 15px;
}
.title {
  font-weight: bold;
}
.el-row {
  margin-bottom: 10px;
}
.el-card {
  border-radius: 10px;
  .el-card__body {
    padding: 15px;
  }
}
</style>