//pages
import Index from '@/views/pages/authentication/Index.vue'
import Login from '@/views/pages/authentication/Login.vue'
import ListPqr from '@/views/apps/modules/administrator/ListPqr.vue'
import ViewPqr from '@/views/apps/modules/administrator/ViewPqr.vue'
import Register from '@/views/pages/authentication/Register.vue'
import ForgotPassword from '@/views/pages/authentication/ForgotPassword.vue'
import layouts from '@/layout'
import store from '../../store'

export default {
	path: 'authentication',
	name: 'Administrador PQR',
	component: Index,
	redirect: { path: '/register', name: 'register' },
	icon: 'account-tie',
	nav: store.getters.isLogged,
	children: [
		{
			path: '/pqr-list',
			name: 'Listado de PQR',
			component: ListPqr,
			meta: {
				nav: true,
				layout: layouts.navTop,
				icon: 'file-eye-outline',
			}
		},
		{
			path: '/pqr-view/:id',
			name: 'Ver de PQR',
			component: ViewPqr,
			meta: {
				nav: false,
				layout: layouts.navTop,
				icon: 'file-eye-outline',
			}
		},
		{
			path: '/appeals',
			name: 'Apelaciones',
			component: Register,
			meta: {
				nav: false,
				layout: layouts.navTop,
				icon: 'cash-multiple',
			}
		},
		{
			path: '/reports',
			name: 'Informes',
			component: Register,
			meta: {
				nav: false,
				layout: layouts.navTop,
				icon: 'file-search-outline',
			}
		},
		{
			path: '/procedures',
			name: 'Procedimientos',
			component: Register,
			meta: {
				nav: false,
				layout: layouts.navTop,
				icon: 'chart-timeline',
			}
		},
		{
			path: '/manual',
			name: 'Manual de Usuario',
			component: Register,
			meta: {
				nav: true,
				layout: layouts.navTop,
				icon: 'file-pdf-outline',
			}
		},
		{
			path: '/register',
			name: 'Crear Usuario',
			component: Register,
			meta: {
				nav: true,
				layout: layouts.navTop,
				icon: 'account-plus-outline',
			}
		},
	]
}
