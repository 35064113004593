<template lang="html">
  <router-view/>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
