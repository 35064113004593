import request from '@/utils/request'

export function getAllPqr(pageSize, currentPage, status) {
  return request({
    url: 'administrator/getAllPqr/' + pageSize + '/' + status + '?page=' + currentPage,
    method: 'get'
  });
}

export function getState() {
  return request({
    url: 'administrator/getState',
    method: 'get'
  });
}

export function saveAnswerPqr(data) {
  return request({
    url: 'administrator/saveAnswerPqr',
    method: 'post',
    data
  });
}

export function getPqrById(id) {
  return request({
    url: 'administrator/getPqrById/' + id,
    method: 'get'
  });
}

export function closePqr(id) {
  return request({
    url: 'administrator/closePqr/' + id,
    method: 'get'
  });
}

export function openPqr(id) {
  return request({
    url: 'administrator/openPqr/' + id,
    method: 'get'
  });
}

export function downloadPqr(data) {
  return request({
    url: 'administrator/downloadPqr',
    method: 'post',
    data,
    responseType: 'arraybuffer'
  });
}

