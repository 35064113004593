<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="24">
        <el-col :span="15">
          <h2>
            Mostrando PQR
            {{ filter == 0 ? "Abiertos" : filter == 1 ? "Cerrados" : "Todos" }}
          </h2>
        </el-col>
        <el-col :span="9" style="text-align: end">
          <el-dropdown @command="handleCommandStatus">
            <el-button>
              <i
                class="mdi mdi-filter-variant"
                style="vertical-align: middle"
              ></i>
              Filtrar<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-lock" command="1"
                >Cerrados</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-unlock" command="0"
                >Abiertos</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-files" command="2"
                >Todos</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <label></label>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col>
          <el-table
            :data="tableData"
            :default-sort="{ prop: 'fecha_vence', order: 'descending' }"
            style="width: 100%"
            max-height="400"
            v-loading="loadingTable"
          >
            <el-table-column label="CUN-PQR" prop="cun_sic" width="180">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.cun_sic }}
                </div>
                <el-tag
                  size="mini"
                  type="success"
                  v-if="scope.row.cerrar == '0'"
                  ><i class="el-icon-unlock"></i> Abierto</el-tag
                >
                <el-tag size="mini" type="danger" v-if="scope.row.cerrar == '1'"
                  ><i class="el-icon-lock"></i> Cerrado</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="Fecha" prop="created_at" width="100">
              <template slot-scope="scope">
                <div>{{ scope.row.created_at.substring(0, 10) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="Vence"
              prop="fecha_vence"
              sortable
              width="100"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.fecha_vence.substring(0, 10) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Descripción" prop="descripcion">
              <template slot-scope="scope">
                <div class="description">
                  {{ scope.row.descripcion }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Notificación"
              prop="notificaciones"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.notificaciones === 0">
                  <i class="mdi mdi-email-outline"></i>
                </div>
                <div v-else><i class="mdi mdi-email"></i></div>
              </template>
            </el-table-column>
            <el-table-column label="Estado" prop="state.estado" width="180">
            </el-table-column>
            <!-- <el-table-column label="Email" prop="email"> </el-table-column> -->
            <el-table-column align="right" width="130">
              <template slot-scope="scope">
                <el-dropdown @command="handleCommand">
                  <el-button type="primary" size="mini">
                    Acciones<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{ id: 1, data: scope.row }"
                      ><i class="mdi mdi-message-text-outline"></i> Contestar
                      PQR</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ id: 2, data: scope.row }"
                      ><i class="mdi mdi-comment-eye-outline"></i> Ver
                      PQR</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ id: 3, data: scope.row }"
                      ><i class="mdi mdi-printer"></i> Imprimir
                      PQR</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="{ id: 4, data: scope.row }"
                      v-if="scope.row.cerrar === 0"
                      divided
                      ><span class="btn-close"
                        ><i class="mdi mdi-lock-outline"></i> Cerrar</span
                      ></el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="{ id: 5, data: scope.row }"
                      v-if="scope.row.cerrar === 1"
                      divided
                      ><span class="btn-open"
                        ><i class="mdi mdi-lock-open-outline"></i> Abrir</span
                      ></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <div
            v-if="tableData.length > 0"
            class="pagination-box animated fadeInRight"
            style="text-align: end; padding-top: 10px"
          >
            <el-pagination
              layout="total, prev, pager, next"
              :page-size="pageSize"
              :total="total_rows"
              @current-change="handleCurrentPage"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <modal-answer
      :open="openModalAnswer"
      :pqr_id="pqrId"
      :cun_sic="cunSic"
      @close="closeModal"
    ></modal-answer>
    <modal-print
      :open="openModalPrint"
      :data="dataPrint"
      @close="closeModalPrint"
    ></modal-print>
  </div>
</template>
<script>
import ModalAnswer from "./ModalAnswer.vue";
import ModalPrint from "./ModalPrint.vue";
import { getAllPqr, closePqr, openPqr, downloadPqr } from "@/api/administrator";
export default {
  name: "ListPqr",
  components: {
    ModalAnswer,
    ModalPrint,
  },
  data() {
    return {
      openModalAnswer: false,
      openModalPrint: false,
      loadingTable: false,
      loadingDownload: false,
      filter: 0,
      tableData: [],
      pqrId: "",
      cunSic: "",
      dataPrint: null,
      //
      pageSize: 50,
      currentPage: 1,
      total_rows: 0,
      search: "",
    };
  },
  methods: {
    handleCommandStatus(command) {
      this.filter = command;
      this.getAll(command);
    },
    getAll(command) {
      this.loadingTable = true;
      var status = typeof command != "undefined" ? command : 0;
      getAllPqr(this.pageSize, this.currentPage, this.filter)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
        })
        .catch(function (error) {
          this.loadingTable = false;
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll();
    },
    closeModal() {
      this.openModalAnswer = false;
      this.pqrId = "";
      this.cunSic = "";
    },
    closeModalPrint() {
      this.openModalPrint = false;
      this.dataPrint = null;
    },
    handleCommand(command, row) {
      let me = this;
      if (command.id == 1) {
        this.openModalAnswer = true;
        this.pqrId = command.data.id;
        this.cunSic = command.data.cun_sic;
      }
      if (command.id == 2) {
        this.$router.push({
          path: "/pqr-view/" + command.data.id,
        });
      }
      if (command.id == 3) {
        me.downloadPqr(command.data);
      }
      if (command.id == 4) {
        this.$swal
          .fire({
            title: "<strong>Atención!</strong>",
            icon: "warning",
            html: "Deseas cerrar este PQR?",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            focusConfirm: false,
            confirmButtonText:
              '<i class="mdi mdi-clipboard-check-outline" style="vertical-align: middle;"></i> Si',
            cancelButtonText:
              '<i class="mdi mdi-close-circle-outline" style="vertical-align: middle;"></i> No, cancelar',
          })
          .then((result) => {
            if (result.isConfirmed) {
              me.closePqrById(command.data.id);
            } else {
              me.code_product = "";
              me.loading = false;
            }
          });
      }
      if (command.id == 5) {
        this.$swal
          .fire({
            title: "<strong>Atención!</strong>",
            icon: "warning",
            html: "Deseas <strong>Abrir</strong> este PQR?",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            focusConfirm: false,
            confirmButtonText:
              '<i class="mdi mdi-clipboard-check-outline" style="vertical-align: middle;"></i> Si',
            cancelButtonText:
              '<i class="mdi mdi-close-circle-outline" style="vertical-align: middle;"></i> No, cancelar',
          })
          .then((result) => {
            if (result.isConfirmed) {
              me.openPqrById(command.data.id);
            } else {
              me.code_product = "";
              me.loading = false;
            }
          });
      }
    },
    openPqrById(id) {
      this.loadingTable = true;
      openPqr(id)
        .then(({ data }) => {
          this.getAll();
          this.$message({
            type: "success",
            message: "PQR abierto exitosamente",
          });
        })
        .catch(function (error) {
          this.loadingTable = false;
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    closePqrById(id) {
      this.loadingTable = true;
      closePqr(id)
        .then(({ data }) => {
          this.getAll();
          this.$message({
            type: "success",
            message: "PQR cerrado exitosamente",
          });
        })
        .catch(function (error) {
          this.loadingTable = false;
          // this.$message.error('Error registrar cliente.')
          console.log(error);
        });
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    print(data) {
      console.log("imprimir", data);
      this.dataPrint = data;
      this.openModalPrint = true;
    },
    downloadPqr(row) {
      this.loadingDownload = true;
      downloadPqr({ id: row.id })
        .then(({ data }) => {
          // console.log(data);
          this.downloadFile(data, "Soporte PQR");
        })
        .catch(function (error) {
          console.log(error);
          this.loadingDownload = false;
          this.$message.error("Error.");
        });
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
      this.loadingDownload = false;
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>
<style lang="scss" scope>
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  // cursor: pointer;
}
.btn-close {
  color: #f04d7f;
}
.btn-open {
  color: #42d885;
}
</style>