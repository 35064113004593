<template>
  <el-dialog
    title="Contestar PQR"
    :visible.sync="open"
    width="60%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :modal-append-to-body="true"
    :append-to-body="true"
  >
    <label slot="title"
      ><i class="mdi mdi-printer" style="vertical-align: middle"></i> Imprimir
      PQR</label
    >
    <div class="page-invoice scrollable">
      <div class="invoice bg-white black-text">
        <div class="invoice-top">
          <div class="logo">
            <img
              class="logo-mini"
              :src="imageUrl + '/logo.png'"
              alt="logo"
            />
          </div>
          <div class="title">Soporte PQR</div>
          <!--End Title-->
        </div>
        <div class="invoice-middle">
          <el-row :gutter="20">
            <el-col :span="24" class="info">
              <el-row :gutter="gutter" class="row-data">
                <el-col :span="spanLeft"
                  ><span class="title">CUN:</span>
                  {{ dataInfo.cun_sic }}</el-col
                >
                <el-col :span="spanRigth"
                  ><span class="title">Nombre:</span>
                  {{ concatName(dataInfo) }}</el-col
                >
              </el-row>
              <el-row :gutter="gutter" class="row-data">
                <el-col :span="spanLeft">
                  <span class="title">Fecha:</span>
                  {{
                    dataInfo.created_at
                      ? dataInfo.created_at.substring(0, 10)
                      : ""
                  }}
                  &nbsp;&nbsp;&nbsp;
                  <span class="title">Vence:</span>
                  {{
                    dataInfo.fecha_vence
                      ? dataInfo.fecha_vence.substring(0, 10)
                      : ""
                  }}
                </el-col>
                <el-col :span="spanRigth"
                  ><span class="title">Teléfono:</span>
                  {{ dataInfo.telefono }}</el-col
                >
              </el-row>
              <el-row :gutter="gutter" class="row-data">
                <el-col :span="spanLeft"
                  ><span class="title">Tipo PQR:</span>
                  {{
                    dataInfo.complaint ? dataInfo.complaint.tipo_queja : ""
                  }}</el-col
                >
                <el-col :span="spanRigth"
                  ><span class="title">Trámite:</span>
                  {{
                    dataInfo.procedure ? dataInfo.procedure.tipo_tramite : ""
                  }}</el-col
                >
              </el-row>
              <el-row :gutter="gutter" class="row-data">
                <el-col :span="spanLeft"
                  ><span class="title">Estado:</span>
                  {{ dataInfo.state ? dataInfo.state.estado : "" }}</el-col
                >
                <el-col :span="spanRigth"
                  ><span class="title">Dirección:</span>
                  {{ dataInfo.direccion }}</el-col
                >
              </el-row>
              <el-row :gutter="gutter" class="row-data">
                <el-col :span="spanLeft"
                  ><span class="title">Adjuntos:</span>
                  <a
                    v-if="dataInfo.adjunto"
                    :href="pathUrlDocs + dataInfo.adjunto"
                    target="_blank"
                  >
                    {{ dataInfo.adjunto }}</a
                  >
                  <span v-else> Sin Adjunto</span>
                </el-col>
                <el-col :span="spanRigth"
                  ><span class="title">Ciudad:</span>
                  {{ dataInfo.city ? dataInfo.city.ciudad : "" }}</el-col
                >
              </el-row>
              <el-row :gutter="gutter" class="row-data">
                <el-col :span="24"
                  ><span class="title">Email:</span>
                  {{ dataInfo.email }}</el-col
                >
              </el-row>
              <el-row :gutter="gutter" class="row-data">
                <el-col :span="24"
                  ><span class="title">Queja:</span>
                  {{ dataInfo.descripcion }}</el-col
                >
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24"></el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <!--End InvoiceTop-->

        <hr />

        <div class="invoice-middle footer">
          <h1>
            <i class="mdi mdi-history" style="vertical-align: middle"></i>
            Historial de respuestas
          </h1>
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            :default-sort="{ prop: 'created_at', order: 'descending' }"
            v-loading="loadingTable"
          >
            <el-table-column prop="created_at" label="Creado Por" width="150">
              <template slot-scope="scope">
                <div>{{ scope.row.user.name }}</div>
                <small>{{ scope.row.created_at.substring(0, 10) }}</small>
              </template>
            </el-table-column>
            <el-table-column prop="descripcion_detalle" label="Descripción">
            </el-table-column>
            <el-table-column prop="estado" label="Cod. Estado" width="100">
            </el-table-column>
          </el-table>
        </div>

        <!--End Invoice-->
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small"
        ><i class="el-icon-close"></i> Cancelar</el-button
      >
      <el-button type="primary" size="small" @click="print"
        ><i class="el-icon-printer"></i> Imprimir</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ModalPrint",
  props: ["open", "data"],
  watch: {
    data(newVal, oldVal) {
      var me = this;
      this.loadingTable = true;
      if (newVal) {
        this.dataInfo = newVal;
        this.tableData = newVal.detail;
      }
      setTimeout(() => {
        me.loadingTable = false;
      }, 500);
    },
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_ROOT+'images/'+process.env.VUE_APP_ROOT_FOLDER,
      pathUrlDocs: process.env.VUE_APP_ROOT + "static/images/uploads/",
      loadingTable: false,
      spanLeft: 14,
      spanRigth: 10,
      gutter: 20,
      dataInfo: [],
      tableData: [],
    };
  },
  methods: {
    print() {
      window.print();
    },
    handleClose(done) {
      this.$emit("close", true);
    },
    concatName(data) {
      return (
        data.primer_nombre +
        " " +
        this.extractNull(data.segundo_nombre) +
        " " +
        this.extractNull(data.primer_apellido) +
        " " +
        this.extractNull(data.segundo_apellido)
      ).trim();
    },
    extractNull(data) {
      return data == null || data == "null" ? "" : data;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.page-invoice {
  word-break: normal !important;
  .row-data {
    margin-bottom: 18px !important;
    border-bottom: 1px solid #d2d2d2;
  }

  .toolbar {
    max-width: 794px;
    margin: 0 auto;
    text-align: right;
    margin-bottom: 20px;
  }

  .invoice {
    background: white;
    width: 100%;
    max-width: 794px;
    margin: 0 auto;
    padding: 2em;
    box-sizing: border-box;
    box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.3);

    hr {
      border: 1px solid rgba(0, 0, 0, 0.05);
    }

    .invoice-top {
      overflow: hidden;
      margin-bottom: 30px;

      .logo {
        float: left;
        box-sizing: border-box;
        overflow: hidden;
        text-align: center;

        img {
          width: 70%;
          float: left;
        }
      }
      .info {
        display: block;
        float: left;
        margin-left: 20px;
        margin-top: 5px;

        & > * {
          margin: 0;
        }
      }
      .title {
        font-size: xx-large;
        margin-top: 5px;
        color: #6e6e6e;

        & > * {
          margin: 0;
        }

        p {
          text-align: right;
        }
      }
    }

    .invoice-middle {
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 30px;

      .clientlogo {
        float: left;
        height: 60px;
        width: 60px;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        background: grey;

        img {
          width: 100%;
        }
      }

      .info {
        display: block;
        float: left;
        margin-left: 20px;
        margin-top: 5px;

        & > * {
          margin: 0;
        }
      }

      .project {
        margin-left: 52%;

        & > * {
          margin: 0;
        }
      }
    }

    .invoice-bottom {
      table {
        width: 100%;
        border-collapse: collapse;

        th {
          text-align: right;
          padding: 20px;
          padding-bottom: 5px;
          opacity: 0.7;

          &:first-child {
            text-align: left;
            padding-left: 0px;
            width: 100%;
          }
          &:last-child {
            padding-right: 0px;
          }
        }

        td {
          text-align: right;
          padding: 20px;
          padding-bottom: 5px;
          border-bottom: 1px solid #eee;

          &:first-child {
            text-align: left;
            padding-left: 0px;
            width: 100%;
          }
          &:last-child {
            padding-right: 0px;
          }
        }

        tfoot td {
          border: none;
          padding-top: 5px;
        }
      }
    }

    h1 {
      font-size: 1.5em;
      color: #222;
    }
    h2 {
      font-size: 0.9em;
    }
    h3 {
      font-size: 1.2em;
      font-weight: 300;
      line-height: 2em;
    }
    p {
      font-size: 0.7em;
      color: #666;
      line-height: 1.2em;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-invoice {
    .invoice {
      .invoice-middle {
        .project {
          display: block;
          width: 100%;
          clear: both;
          padding: 10px 0;
          margin: 0;
        }
      }
      .invoice-bottom {
        table {
          th {
            padding: 5px;
          }

          td {
            padding: 5px;
          }
        }
      }
    }
  }
}

@media print {
  @page {
    size: letter;
    margin: 0;
  }
    .footer {
      page-break-after: always;
    }

  .el-dialog__wrapper {
    overflow: initial !important;
  }
  .v-modal,
  .container {
    display: none;
  }

  .page-invoice {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }

  .el-dialog {
    margin-top: 0px !important;
    width: 100% !important;
    min-height: 1000px !important;
  }

  .el-dialog__header,
  .el-dialog__footer {
    display: none;
  }

  .layout-container .container .footer,
  .layout-container .container .header,
  .layout-container .container .horizontal-nav,
  .layout-container .footer,
  .layout-container .layout-picker,
  .layout-container .vertical-nav,
  .layout-container .header {
    display: none;
  }

  .page-invoice {
    .toolbar {
      display: none;
    }

    .invoice {
      box-shadow: none;
      max-width: 100%;
      padding: 0;
    }
  }
}
</style>


